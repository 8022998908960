import {React, useState, useEffect} from "react";
import SpotLight from "./SpotLight";



function  WorkFlows(){
    return (

        <section>
        <div className="mx-auto max-w-6xl px-4 sm:px-6">
          <div className="pb-12 md:pb-20">
            {/* Section header */}
            <div className="mx-auto max-w-3xl pb-12 text-center md:pb-20">
              <div className="inline-flex items-center gap-3 pb-3 before:h-px before:w-8 before:bg-gradient-to-r before:from-transparent before:to-indigo-200/50 after:h-px after:w-8 after:bg-gradient-to-l after:from-transparent after:to-indigo-200/50">
                <span className="inline-flex text-indigo-500">
                  Boost your memory
                </span>
              </div>
              <h2 className="animate-[gradient_6s_linear_infinite] text-indigo-500 pb-4 font-nacelle text-xl font-semibold  md:text-2xl">
                Always keep learning
              </h2>
              <p className="text-lg text-gray-700 dark:text-gray-400">
                Simple to use and elegant web application to help you learn more effectively. 
                There are tons of ressources that can help you learn and develop new skills in a fun way, from free books, to tutorials and quizzes.
              </p>
            </div>
            <SpotLight/>
          </div>
        </div>
      </section>

    )
}

export default WorkFlows;