import {React,  useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';
// Using ES6 import syntax
import hljs from 'highlight.js/lib/core';
import ada from 'highlight.js/lib/languages/ada';
import cpp from 'highlight.js/lib/languages/cpp';
import csharp from 'highlight.js/lib/languages/csharp';
import java from 'highlight.js/lib/languages/java';
import python from 'highlight.js/lib/languages/python';
import kotlin from 'highlight.js/lib/languages/kotlin';
import dart from 'highlight.js/lib/languages/dart';
import swift from 'highlight.js/lib/languages/swift';
import ocaml from 'highlight.js/lib/languages/ocaml';
import rust from 'highlight.js/lib/languages/rust';   //for gleam
import delphi from 'highlight.js/lib/languages/delphi';  //for fpc
import 'highlight.js/styles/atom-one-dark.min.css'; // Import a light mode Highlight.js style
import 'highlightjs-badge/highlightjs-badge.min.js'; //highlightjs-badge for the badge


function TutorialFooter({date}) {
  return (
    <footer className="footer footer-center text-gray-700 dark:text-gray-400">
      <hr />
      <aside>
      <p  className="px-auto flex justify-center items-center text-sm mb-6">Last updated on &nbsp; {date}   </p>
      </aside>
    </footer>
  );
}




function ArticleDisplay({title, category, content}){
    // Then register the languages you need
    hljs.registerLanguage('ada', ada);
    hljs.registerLanguage('delphi', delphi);
    hljs.registerLanguage('cpp', cpp);
    hljs.registerLanguage('csharp', csharp);
    hljs.registerLanguage('java', java);
    hljs.registerLanguage('python', python);
    hljs.registerLanguage('kotlin', kotlin);

  useEffect(() => {
    hljs.highlightAll();
    window.highlightJsBadge();
  }, [])
  return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={title}  />
        </Helmet>
      <section className="body-font text-base text-black-500 dark:text-white flex justify-center items-center">
        <div className="container px-5 py-24 mx-auto">
        <div class="mt-6 px-4 btn-group btn-group-scrollable">
	        <a href={`/tutorials/${category}`} class="btn  btn-ghost px-2 text-sky-600 text-sm"><ion-icon name="arrow-back"></ion-icon>&nbsp; {category}</a>
        </div>
          <h2 className="flex justify-center items-center px-auto font-semibold  text-xl text-bold  text-sky-500">{title}</h2> 
          <hr /> <br /> 
            <div className="px-2 py-2">
              <div dangerouslySetInnerHTML={{__html : content} }></div>
            </div><br /> 
        </div>

    </section> 

      </>

  );
}


function TutorialDisplay (){
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    // Get a specific parameter
    let { id } = useParams();

    useEffect(() => {
        const fileID = parseInt(id);

        //console.log(id)
        const url = `https://goldfish-app-asx3v.ondigitalocean.app/tutorials/${fileID}`;

        fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result);


          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
      }, [id])// <-- }, [])  empty array means 'run once'


      if (error) {
        return <div><br /><br /><br />Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div><br /><br /><br />Loading...</div>;
      } else {

        return(
          <div  className="z-0">
              <div className="flex flex-col h-screen justify-between">
                <main className="mb-auto">
                  { 
                    <ArticleDisplay
                    key={items.Id} 
                    title={items.Title}
                    category={items.Category}
                    content={items.Content}
                    ></ArticleDisplay>
                  }
                </main>
              <footer className="h-10">
                <TutorialFooter date={items.Date}></TutorialFooter>
              </footer>
            </div>
          </div>
      );}



}

export  {TutorialDisplay};