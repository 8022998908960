import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import cpp from "../../assets/images/cpp.jpg";
import python from "../../assets/images/python.jpg";
import cs from "../../assets/images/cs.jpg";
import java from "../../assets/images/java.png";
import go from "../../assets/images/go.jpg";
import rust from "../../assets/images/rust.jpg";
import kotlin from "../../assets/images/kotlin.jpg";
import javascript from "../../assets/images/javascript.jpg";
import pascal from "../../assets/images/pascal.jpg";
import ada from "../../assets/images/ada.jpg";
import gleam from "../../assets/images/gleam.jpg";
import zig from "../../assets/images/zig.jpg";
import elixir from "../../assets/images/elixir.jpg";
import ocaml from "../../assets/images/ocaml.jpg";
import dart from "../../assets/images/dart.png";
import swift from "../../assets/images/swift2.png";
import sibelius from "../../assets/images/sibelius.jpg";
import finale from "../../assets/images/finale.jpg";
import theory from "../../assets/images/music.jpg";
import autocad from "../../assets/images/autocad.jpg";
import excel from "../../assets/images/excel.jpg";
import word from "../../assets/images/word.jpg";
import powerpoint from "../../assets/images/powerpoint.jpg";
import ai from "../../assets/images/ai.jpg";
import other from "../../assets/images/other.jpg";


function TutorialCategoryCard({id, image, category}){
  return (
    <>
      <div className="md:w-1/3 lg:w-1/3 w-full  text-gray-900 dark:text-white">
        <div className="card shadow w-80 m-4">
          <figure>
            <img src={image} alt="" />
          </figure>
          <div className="card-body flex items-center" >
            <img className="avatar" src={image} alt="" />
              <h2 className="card-title"> {category}</h2>
              { (id%2 === 0) &&
                <a  className="w-full btn bg-orange-700 hover:bg-orange-900 text-white btn-md" href={`/tutorials/${category}`}><ion-icon name="arrow-forward-outline"></ion-icon></a>
              }
              { (id%2 === 1) &&
                <a  className="w-full btn bg-blue-700 hover:bg-blue-900 text-white btn-md" href={`/tutorials/${category}`}><ion-icon name="arrow-forward-outline"></ion-icon></a>
              }
          </div>
        </div>

      </div>
    </>
  );
}



function TutorialsMain() {
  var items = [
    {Id:1, Category:"C++", Image:cpp},
    {Id:2, Category:"Python", Image:python},
    {Id:3, Category:"Csharp", Image:cs},
    {Id:4, Category:"Java", Image:java},
    //{Id:5, Category:"Go", Image:go},
    //{Id:6, Category:"Rust", Image:rust},
    {Id:7, Category:"Kotlin", Image:kotlin},
    //{Id:8, Category:"Javascript", Image:javascript},
    {Id:9, Category:"Free Pascal", Image:pascal},
    {Id:10, Category:"Ada", Image:ada},
    //{Id:11, Category:"Gleam", Image:gleam},
    //{Id:12, Category:"Zig", Image:zig},
    //{Id:13, Category:"Elixir", Image:elixir},
    //{Id:14, Category:"Ocaml", Image:ocaml},
    //{Id:15, Category:"Dart", Image:dart},
    //{Id:16, Category:"Swift", Image:swift},
    //{Id:17, Category:"Sibelius", Image:sibelius},
    //{Id:18, Category:"Finale", Image:finale},
    //{Id:19, Category:"Music Theory", Image:theory},
    //{Id:20, Category:"AutoCad", Image:autocad},
    //{Id:21, Category:"Excel", Image:excel},
    //{Id:22, Category:"Word", Image:word},
    //{Id:23, Category:"PowerPoint", Image:powerpoint},
    //{Id:24, Category:"AI", Image:ai},
    //{Id:25, Category:"Other", Image:other},
  ];
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Instantly get access to thousands of tutorials." />
          <meta property="og:description" content="Access free tutorials in programming, music theory, music notation, CAD, AI, office productivity, etc."/>
        </Helmet>
        <section className="relative">
        <div className="mx-auto max-w-6xl px-4 sm:px-6">
          <div className="border-t py-12 [border-image:linear-gradient(to_right,transparent,theme(colors.slate.400/.25),transparent)1] md:py-20">

          <div className=" py-12 container mx-auto px-2">
            <h2 className="flex justify-center items-center text-black-500 dark:text-sky-600 py-4 text-xl"><b>Tutorials</b></h2>
              <p>
              Tutorials are essential and offer numerous benefits. 
              Our tutorial series now mainly covers programming languages. 
              However, we intend to expand it to music notation software, CAD, AI, and office productivity software shortly. 
              What you get with our tutorials is:</p>
              <p><b>Convenience and flexibility</b>: You can access our tutorials from anywhere and anytime.</p>
              <p><b>Expanded opportunities</b>: Whether in remote or under-resourced areas, access our tutorials at your own pace.
              </p>
            </div>
          <div className="py-5 mx-auto flex flex-wrap">
            {items.map(tuto => 
                <TutorialCategoryCard key={tuto.Id} 
                id = {tuto.Id}
                category={tuto.Category}
                image={tuto.Image}
                >
              </TutorialCategoryCard>)}
          </div>
          </div>
        </div>
      </section>

      </>

    );
  }
  export default TutorialsMain;