import React from "react";
import {Helmet} from "react-helmet";

export default function Policy(){
    return (
        <>
        <br /><br />
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Privacy policy" />
          <meta property="og:description" content="Privacy policy of the Library of the People website."/>
        </Helmet>         
          <div className="py-6 sm:py-12">
            <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
              <div className="mx-auto max-w-md px-4">
                <h2><b>Privacy Policy</b></h2>
                <div className="divide-y divide-gray-300/50">
                  <div className="space-y-6 py-8 text-base leading-7 text-gray-600 dark:text-white">
                  <h5>
                  This privacy policy will help you understand how 
                  <a className="text-blue-500 hover:text-blue-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer"> Arfusoft </a> 
                  uses and protects the data you provide to us when you visit and use <span className="font-bold text-purple-500">libpeople.com</span>.
We reserve the right to change this policy at any given time, at which time you will be promptly updated. If you want to ensure you are up to date with the latest changes, we advise you to visit this page frequently.
<br/>✤ <b>What User Data We Collect</b><br/>
When you visit the website, we may collect the following data:
Your IP address.
Your contact information and email address.
Other information, such as interests and preferences.
Data profile regarding your online behavior on our website.
<br/>✤ <b>Why We Collect Your Data</b><br/>
We are collecting your data for several reasons:
To better understand your needs.
To improve our services and products.
To send you promotional emails containing the information you will find interesting.
To contact you to fill out surveys and participate in other types of market research.
To customize our website according to your online behavior and personal preferences.
<br/>✤ <b>Safeguarding and Securing the Data</b><br/>
Arfusoft is committed to securing your data and keeping it confidential. Arfusoft has done all its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online.
<br/>✤ <b>Our Cookie Policy</b><br/>
Once you agree to allow our website to use cookies, you also agree to use the data it collects regarding your online behavior (analyze web traffic and web pages you visit and spend the most time on).
The data we collect using cookies is used to customize our website to your needs. After we use the data for statistical analysis, the data is completely removed from our systems.
Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used to monitor which pages you find useful and which you do not so that we can provide a better experience for you.
If you want to turn off cookies, you can do so by accessing your internet browser's settings. You can visit <a className="text-blue-400 hover:text-blue-600 font-bold" href="https://www.internetcookies.com" target="_blank" rel="noreferrer">Internetcookies</a>, which contains comprehensive information on how to do this on various browsers and devices.
<br/>✤ <b>Links to Other Websites</b><br/>
Our website contains links that lead to other websites. If you click on these links, Arfusoft will not be held responsible for protecting your data and privacy. Visiting those websites is not governed by this privacy policy agreement. Make sure to read the privacy policy documentation of the website you go to from our website.
<br/>✤ <b>Restricting the Collection of Your Personal Data</b><br/>
At some point, you might want to restrict the use and collection of your data. You can achieve this by doing the following:
When filling out the forms on the website, make sure to check if there is a box you can leave unchecked if you don't want to disclose your personal information.
If you have already agreed to share your information with us, please get in touch with us via email; we will be more than happy to change this for you.
Arfusoft will not lease, sell, or distribute your personal information to any third parties unless you give it your permission. We might do so if the law forces us. If you agree to this privacy policy, your personal information will be used when we send you promotional materials. 


                        </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
        </>
    
      );
}